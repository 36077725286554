import Vue from 'vue'
import Vuex from 'vuex'
import vapor from "@/plugins/vapor";
import avTechnicianTranscodeVod from "@/graphql/mutations/avTechnicianTranscodeVod.graphql";
import _map from "lodash/map";

const beforeUnloadListener = (event) => {
    event.preventDefault();
    return event.returnValue = "Are you sure you want to exit?";
};

Vue.use(Vuex)

const videoUploads = {
    namespaced: true,
    state: () => ({
        list: {},
        refetchQuery: null,
    }),
    mutations: {
        addUpload(state, payload) {
            Vue.set(state.list, payload.id, {
                id: payload.id,
                name: payload.name,
                progress: 0
            })
            removeEventListener("beforeunload", beforeUnloadListener, {capture: true});
            addEventListener("beforeunload", beforeUnloadListener, {capture: true});
        },
        setRefetchQuery(state, query) {
            state.refetchQuery = query
        },
        updateProgress(state, payload) {

            const upload = state.list[payload.id]
            if (upload) {
                state.list[payload.id].progress = payload.progress
            }
        },
        removeUpload(state, id) {
            Vue.delete(state.list, id)
            removeEventListener("beforeunload", beforeUnloadListener, {capture: true});
        }
    },
    actions: {
        async start({state, dispatch, commit}, payload) {
            console.log(payload)
            commit('addUpload', {
                id: payload.livestreamId,
                name: payload.livestreamName,
                progress: 0
            })

            let uploadPath = await vapor.storeMultipart(
                payload.video,
                payload.apollo,
                (progress) => {
                    commit('updateProgress', {progress: progress, id: payload.livestreamId})
                })
                .catch((e) => {
                    console.log(e)
                    commit('removeUpload', payload.livestreamId)
                    dispatch('errorAlert/serverErrors', e, {root: true})
                });
            commit('updateProgress', {progress: 0, id: payload.livestreamId})
            if (uploadPath) {
                payload.apollo.mutate({
                    mutation: avTechnicianTranscodeVod,
                    variables: {
                        livestreamId: payload.livestreamId,
                        sourcePath: uploadPath,
                    },
                }).then(() => {
                    commit('removeUpload', payload.livestreamId)
                    if (state.refetchQuery) {
                        state.refetchQuery.refetch()
                    }
                }).finally(() => {
                    this.loading = false;
                });
            } else {
                dispatch('errorAlert/serverErrors', {}, {root: true})
                this.loading = false
            }
        },
    }
}

const successAlert = {
    namespaced: true,
    state: () => ({
        active: false,
        text: '',
    }),
    mutations: {
        setActive(state, active) {
            state.active = active
        },
        setText(state, text) {
            state.text = text
        },
    },
    actions: {
        success({commit}, text = 'Erfolgreich gespeichert!') {
            commit('setText', text)
            commit('setActive', true)
            commit('errorAlert/setActive', false, {root: true})
        }
    }
}

const errorAlert = {
    namespaced: true,
    state: () => ({
        active: false,
        serverErrors: [],
    }),
    mutations: {
        setActive(state, active) {
            state.active = active
        },
        setServerErrors(state, errors) {
            state.serverErrors = errors
        },
    },
    actions: {
        serverErrors({commit}, {e, message}) {
            if (e && 'graphQLErrors' in e) {
                let messages = e.graphQLErrors[0].extensions.validation;
                if (messages) {
                    commit('setServerErrors', _map(messages, (message) => {
                        return message[0]
                    }))
                } else {
                    commit('setServerErrors', ['Es ist ein unerwarteter Fehler aufgetreten'])
                }
            } else if (message) {
                commit('setServerErrors', [message])
            } else {
                commit('setServerErrors', ['Es ist ein unerwarteter Fehler aufgetreten'])
            }
            commit('setActive', true)
            commit('successAlert/setActive', false, {root: true})
        }
    }
}
export default new Vuex.Store({
    modules: {
        successAlert: successAlert,
        errorAlert: errorAlert,
        videoUploads: videoUploads,
    },
})
